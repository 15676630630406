<template>
  <div>
    <!-- Timeline -->
    <v-card flat>
      <!-- <v-toolbar dense dark color="primary darken-1" elevation="0">
        <v-toolbar-title v-text="'Timeline'" />
      </v-toolbar> -->

      <v-card-text class="pa-0">
        <v-stepper :value="steps.length">
          <v-stepper-header>
            <template v-for="(step, i) in steps">
              <v-stepper-step
                :key="`step-${i}`"
                :step="i + 1"
                :complete="true"
                :editable="step.editable || step.nav > -1"
                :edit-icon="step.nav > -1 ? 'check' : undefined"
                :color="step.color ? step.color : !step.userCreated && !step.disabled ? 'primary darken-1' : 'grey'"
                @click="() => onClick(step)"
                class="text-capitalize"
              >
                {{ step.label }}
                <small v-if="step.sub" v-text="step.sub" />
              </v-stepper-step>
              <v-divider v-if="i + 1 < steps.length" :key="`step-divider-${i}`" />
            </template>
          </v-stepper-header>
        </v-stepper>

        <small>* For demo purposes, any custom changes to the timeline will not persist.</small>
      </v-card-text>
    </v-card>

    <!-- Edit dialog -->
    <v-dialog
      v-model="dialog"
      max-width="20rem"
      width="100%"
    >
      <v-card>
        <v-card-title>
          Add item to timeline
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-form @submit.stop.prevent>
            <v-text-field
              autofocus
              v-model.trim="form.label"
              label="Label"
              placeholder="Label"
              dense
              single-line
              hide-details="auto"
              color="primary darken-1"
              class="mt-3"
              required
              @input="validate"
              :error-messages="errors"
            />
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mr-2"
            color="primary darken-1"
            text
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary darken-1"
            depressed
            @click="addUserStep"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

const FORM = {
  label: ''
}

export default {
  name: 'pibot-eprs-timeline',
  mixins: [validationMixin],
  props: {
    incident: {
      type: Object,
      required: true
    }
  },
  validations: {
    form: {
      label: {
        required
      }
    }
  },
  data: () => ({
    stepper: 1,
    dialog: false,
    form: {
      ...FORM
    },
    userSteps: [],
    errors: []
  }),
  computed: {
    aid () {
      return Number(this.$route.params.aid)
    },
    steps () {
      let steps = []

      if (this.incident.date_of_notification) {
        steps.push({
          label: 'Notification',
          sub: this.formatTimestamp(this.incident.date_of_notification),
          disabled: true
        })
      }

      steps = [
        ...steps,
        { label: 'Incident creation', sub: this.formatTimestamp(this.incident.date_of_occurance), disabled: true },
        ...this.assessmentSteps,
        ...this.userSteps,
        { label: 'Add item', editable: true }
      ]

      return steps
    },
    assessmentSteps () {
      return this.incident.assessments.map((a, i) => {
        const t = a.data.find(d => d.key === 'assessment-time')
        if (!t) return null

        const step = {
          label: a.assessment_type,
          sub: this.formatTimestamp(t.value),
          nav: a.assessment_id,
          color: a.assessment_id === this.aid ? 'primary lighten-1' : undefined
        }

        // if (i !== this.aid - 1) step.nav = i

        return step
      }).filter(a => !!a)
    }
  },
  created () {
    this.stepper = this.steps.length
  },
  methods: {
    onClick (step) {
      if (step.editable) this.showDialog()
      if (step.nav > -1 && step.nav !== this.aid) this.nav(step.nav)
    },
    formatTimestamp (t) {
      let format = 'D MMM YY HH:mm:ss'

      if (this.inThisDay(t)) format = 'ddd HH:mm:ss'
      else if (this.inThisWeek(t)) format = 'ddd HH:mm:ss'
      else if (this.inThisMonth(t)) format = 'ddd D HH:mm:ss'
      else if (this.inThisYear(t)) format = 'D MMM HH:mm:ss'

      return moment(t).format(format)
    },
    inThisYear (timestamp) {
      return moment().format('YYYY') === moment(timestamp).format('YYYY')
    },
    inThisMonth (timestamp) {
      return moment().format('M') === moment(timestamp).format('M')
    },
    inThisWeek (timestamp) {
      return moment().format('W') === moment(timestamp).format('W')
    },
    inThisDay (timestamp) {
      return moment().format('DDD') === moment(timestamp).format('DDD')
    },
    validate () {
      this.$v.$touch()
      const invalid = this.$v.$invalid
      if (invalid) this.errors = ['Invalid label']
      else this.errors = []
      return !this.$v.$invalid
    },
    showDialog () {
      this.dialog = true
    },
    cancel () {
      this.dialog = false
      this.form = { ...FORM }
    },
    addUserStep () {
      const invalid = !this.validate()
      if (invalid) return

      this.dialog = false

      this.userSteps.push({
        ...this.form,
        userCreated: true,
        editable: false,
        sub: this.formatTimestamp(+new Date())
      })

      this.form = { ...FORM }
    },
    nav (i) {
      this.$router.replace({
        name: this.$route.name,
        params: {
          iid: this.$route.params.iid,
          aid: i
        }
      })
    }
  }
}
</script>
